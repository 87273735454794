<template>
  <div style="margin-top: 10px" id="reviewContentDiv">
    <table
      border="1"
      style="
        width: 100%;
        font-size: 14px;
        text-align: center;
        border-collapse: collapse !important;
        border: 1px solid #000;
      "
    >
      <div class="label">每周复盘</div>
      <div class="market-index">
        <div class="title">指数表现回顾</div>
        <div class="market-content">
          <table>
            <thead>
              <tr>
                <th style="width: 15%">指数名称</th>
                <th style="width: 15%">前一周成交额（亿元）</th>
                <th style="width: 15%">本周成交额（亿元）</th>
                <th style="width: 15%">周成交额变化（亿元）</th>
                <th style="width: 15%">指数表现</th>
                <th style="width: 15%">今年以来表现</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in displayContent.market_data.data"
                :key="item.indexId"
              >
                <td>{{ item.indexName }}</td>
                <td>{{ formatNumber(item.lastWeekOBV) }}</td>
                <td>{{ formatNumber(item.thisWeekOBV) }}</td>
                <td>{{ formatNumber(item.towWeekOBV) }}</td>
                <td
                  :class="{
                    redColor: item.indexInfo > 0,
                    greenColor: item.indexInfo < 0,
                  }"
                >
                  {{ formatValue(item.indexInfo) }}
                </td>
                <td
                  :class="{
                    redColor: item.fromThisYearInfo > 0,
                    greenColor: item.fromThisYearInfo < 0,
                  }"
                >
                  {{ formatValue(item.fromThisYearInfo) }}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th style="width: 15%">申万二级指数</th>
                <th style="width: 15%">近 5 日涨跌幅（%）</th>
                <th style="width: 15%">LG 题材</th>
                <th style="width: 15%">近 5 日涨跌幅（%）</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in displayContent.weekDataUp" :key="item.gtsCode">
                <td>{{ item.swcncptName }}</td>
                <td
                  :class="{
                    redColor: item.swchgRate5 > 0,
                    greenColor: item.swchgRate5 < 0,
                  }"
                >
                  {{ formatValue(item.swchgRate5) }}
                </td>
                <td>{{ item.cncptName }}</td>
                <td
                  :class="{
                    redColor: item.chgRate5 > 0,
                    greenColor: item.chgRate5 < 0,
                  }"
                >
                  {{ formatValue(item.chgRate5) }}
                </td>
              </tr>

              <tr
                v-for="item in displayContent.weekDataDown"
                :key="item.gtsCode"
              >
                <td>{{ item.swcncptName }}</td>
                <td
                  :class="{
                    redColor: item.swchgRate5 > 0,
                    greenColor: item.swchgRate5 < 0,
                  }"
                >
                  {{ formatValue(item.swchgRate5) }}
                </td>
                <td>{{ item.cncptName }}</td>
                <td
                  :class="{
                    redColor: item.chgRate5 > 0,
                    greenColor: item.chgRate5 < 0,
                  }"
                >
                  {{ formatValue(item.chgRate5) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 市场点评 -->

      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>市场点评</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.market_review"></p>
        </td>
      </tr>

      <!-- 水煮三股思路 -->
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>水煮三股思路</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.investment_strategy"></p>
        </td>
      </tr>

      <!-- 投资脉络梳理 -->
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <div class="week-invest-context">
            <div class="invest-context-header">
              <div class="title">投资脉络梳理</div>
            </div>
            <div class="week-context-content">
              <div class="label">投资脉络梳理</div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 100px">赛道/题材名称</th>
                    <th style="width: 100px">周涨跌幅</th>
                    <th>周度信息更新</th>
                    <th style="width: 300px">核心标的</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in displayContent.invest_context.data"
                    :key="item.cncptId"
                  >
                    <td>{{ item.cncptName }}</td>
                    <td
                      :class="{
                        redColor: item.chgRate > 0,
                        greenColor: item.chgRate < 0,
                      }"
                    >
                      {{ formatValue(item.chgRate) }}
                    </td>
                    <td style="text-align: left">
                      <div class="html" v-html="item.news"></div>
                    </td>
                    <td>{{ item.components }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import Print from "print-js";
export default {
  components: {},
  props: {
    displayContent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatValue(value) {
      if (typeof value === "undefined") {
        return "";
      }
      value = this.formatPrefix(value);
      value = this.formatPercent(value);
      return value;
    },
    formatPrefix(value) {
      if (value == "--") {
        return value;
      }
      value = parseFloat(value).toFixed(2);
      value = value > 0 ? "+" + value : value;

      return value;
    },
    formatPercent(value) {
      if (value == "--") {
        return value;
      }
      value = value.toString();
      if (!value.includes("%")) {
        value = value + "%";
      }
      return value;
    },
    formatNumber(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseFloat(value);
      const result = (num / 100000000).toFixed(2) + "亿";
      return result;
    },
    formatWan(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseFloat(value);
      const result = (num / 10000).toFixed(2) + "万";
      return result;
    },
    formatInt(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseInt(value);
      return num;
    },
    format(val) {
      return this.timetrans(val);
    },
    getFatherSpan(item) {
      let span = 0;
      item.child.forEach((item) => {
        span += item.stocks.length;
      });
      return span;
    },
    getStockStyle(value) {
      if (value > 0) {
        let opacity = 1 - Math.abs(0.1 - value) / 0.1;
        if (value > 0.1) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(0.1 - value) / 0.1 - 1;
        if (value < -0.1) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    print() {
      this.appStore.printTitle = "每日复盘" + this.displayContent.title;
      this.appStore.printContent =
        document.getElementById("reviewContentDiv").innerHTML;
      let routeUrl = this.$router.resolve({ name: "打印页面" });
      window.open(routeUrl.href, "_blank");
      // printJS({
      //   printable: reviewContentDiv,
      //   type: 'html',
      //   scanStyles: false,
      // });
    },
    timetrans(d, type, f) {
      if (typeof d === "string") {
        d = d.replace(/\-/g, "/");
      }
      let date = new Date(d);
      let Y = date.getFullYear();
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let _M = date.getMonth() + 1;
      let D = !f && date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let w = date.getDay();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      switch (type) {
        case "yyyy年MM月dd日 午HH:mm":
          h = h * 1;
          if (h < 12) {
            h = "上午" + (h < 10 ? "0" + h : h);
          } else {
            h = h - 12;
            h = "下午" + (h < 10 ? "0" + h : h);
          }
          return Y + "年" + M + "月" + D + "日" + "  " + h + ":" + m;
        case "yyyy年MM月dd日":
          return Y + "年" + _M + "月" + D + "日";
        case "yyyy年MM月dd日 星期":
          let arr = [
            "星期天",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
          ];
          return Y + "年" + _M + "月" + D + "日" + "  " + arr[w];
        case "yyyy年MM月":
          return Y + "年" + _M + "月";
        case "yyyy-MM":
          return Y + "-" + _M;
        case "yyyy/MM/dd":
          return Y + "/" + M + "/" + D;
        case "yyyy-MM-dd":
          return Y + "-" + M + "-" + D;
        case "MM-dd":
          return M + "-" + D;
        case "HH:mm:SS":
          return h + ":" + m + ":" + s;
        case "HH:mm":
          return h + ":" + m;
        case "yyyy-MM-dd HH:mm":
          return Y + "-" + M + "-" + D + "  " + h + ":" + m;
        case "MM-dd HH:mm":
          return M + "-" + D + "  " + h + ":" + m;
        case "MM/dd HH:mm":
          return M + "/" + D + "  " + h + ":" + m;
        case "MM月dd日":
          return _M + "月" + D + "日";
        case "YYYY.MM.DD HH:mm":
          return Y + "." + M + "." + D + " " + h + ":" + m;
        case "YYYY.MM.DD":
          return Y + "." + M + "." + D;
        default:
          return Y + "-" + M + "-" + D + "  " + h + ":" + m + ":" + s;
      }
    },
    async previewFile(row) {
      // await this.getFile(row.id)
      //   .then((url) => {
      //     console.log(url);
      //     Bus.$emit("previewFile", { url: url, name: row.name });
      //   })
      //   .catch(() => {});
      console.log(row.storePath);
      console.log(row.storePath);
      let len = row.storePath.split(".").length;
      let nextText = row.storePath.split(".")[len - 1];
      console.log(nextText);
      Bus.$emit("previewFile", {
        url: row.storePath,
        name: row.fileName + "." + nextText,
      });
    },
  },
};
</script>
<style scope lang="scss">
.cncpt-chg {
  .title {
    padding: 10px 0;
  }
  .chg-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cncpt-up-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .cncpt-up-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #f04848;
      background: #fff1f1;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid #f04848;
      }
      .cncptName {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
  .line {
    padding: 20px;
  }
  .cncpt-down-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .cncpt-down-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #22a875;
      background: #ebfbf8;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid #22a875;
      }
      .cncptName {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
}
.sw-chg {
  .title {
    padding: 10px 0;
  }
  .chg-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sw-up-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .sw-up-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #f04848;
      background: #fff1f1;
      border-radius: 8px;
      &:hover {
        border: 1px solid #f04848;
      }
      .name {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
  .line {
    padding: 20px;
  }
  .sw-down-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .sw-down-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #22a875;
      background: #ebfbf8;
      border-radius: 8px;
      &:hover {
        border: 1px solid #22a875;
      }
      .name {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
}
.trade {
  .title {
    padding: 10px 0;
  }
  .trade-content {
    .sub-title {
      padding: 5px 0;
      color: #fff;
      // background-color: #eb5c20;
      background-color: rgb(191, 0, 0);
      text-align: center;
    }
    .trade-header {
      padding: 5px 0;
      color: #000;
      background-color: rgb(255, 242, 204);
      text-align: center;
    }
    table {
      width: 100%;

      border-collapse: collapse;
      border: none;
    }
    thead {
      color: #fff;
    }
    tr {
      &:hover {
        background-color: #fff8ee;
      }
    }
    th {
      padding: 5px 0;
      border: solid#000 1px;
      background-color: rgb(191, 0, 0);
    }
    td {
      padding: 5px 0;
      border: 1px solid #000;
      text-align: center;
    }
  }
}

.hot-cncpt {
  .hot-cncpt-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  }
  .title {
    padding: 10px 0;
  }
  .action {
    color: #c82a29;
  }
  .cncpt-content {
  }
  table {
    width: 100%;

    border-collapse: collapse;
    border: none;
  }
  thead {
    color: #fff;
  }
  tr {
    &:hover {
      background-color: #fff8ee;
    }
  }
  th {
    padding: 5px 0;
    border: solid#000 1px;
    background-color: rgb(191, 0, 0);
  }
  td {
    padding: 5px 0;
    border: 1px solid #000;
    text-align: center;
  }
  .desc {
    text-align: left;
    padding: 10px 5px;
    cursor: pointer;
    &:hover {
      color: var(--g-text-light-highlight);
    }
  }
}
.label {
  padding: 10px 0;
  color: #fff;
  text-align: center;
  background-color: #bf0000;
}
.market-index {
  .title {
    padding: 5px 0;
    color: #000;
    text-align: center;
    background-color: #d7d7d7;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border: none;
  }
  thead {
    color: #fff;
  }
  tr {
    &:hover {
      background-color: #fff8ee;
    }
  }
  th {
    padding: 5px 0;
    border: 1px solid #000;
    background-color: #bf0000;
  }
  td {
    padding: 5px 0;
    border: 1px solid #000;
    text-align: center;
  }
}

.week-market-review {
  .title {
    padding: 10px 0;
  }
}

.invest-strategy {
  .title {
    padding: 10px 0;
  }
}

.week-invest-context {
  .invest-context-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  }
  .title {
    padding: 10px 0;
  }
  .action {
    color: #c82a29;
  }
  .week-context-content {
    .label {
      text-align: center;
      padding: 5px 0;
      background-color: #d7d7d7;
      color: #000;
    }

    table {
      width: 100%;

      border-collapse: collapse;
      border: none;
    }
    thead {
      color: #fff;
    }
    tr {
      &:hover {
        background-color: #fff8ee;
      }
    }
    th {
      padding: 5px 0;
      border: solid#000 1px;
      background-color: #bf0000;
    }
    td {
      padding: 5px 0;
      border: 1px solid #000;
      text-align: center;
    }
    .html {
      text-align: left;
      white-space: pre-wrap;
      line-height: 22px;
    }

    .iconfont {
      cursor: pointer;
      font-size: 20px;
      &:hover {
        color: rgba(255, 255, 255, 1);
        background-color: var(--g-text-light-highlight);
        border-radius: 50%;
      }
    }
  }
}
td p {
  margin: 15px 0;
}
.greenColor {
  color: #04b549 !important;
}
.redColor {
  color: #ea2222 !important;
}
.html {
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  overflow-y: auto;
  box-sizing: border-box;
  img {
    object-fit: contain;
    background-color: #fff;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
